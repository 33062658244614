@import '~izitoast/dist/css/iziToast.min.css';
@import "var";

/*LAYOUT*/
.mpk-layout {
    display: flex;
    justify-content: flex-start;
    &.column {
        flex-direction: column;
        > .flex {
            min-height: 1px
        }
    }
    &.justify-center {
        justify-content: center;
    }
    &.justify-arround {
        justify-content: space-around;
    }
    &.justify-right, &.justify-end {
        justify-content: flex-end;
    }
    &.justify-between {
        justify-content: space-between;
    }
    &.align-center {
        align-items: center
    }
    &.align-start {
        align-items: flex-start
    }
    &.wrap {
        flex-wrap: wrap
    }
    &.fill {
        width: 100%;
        height: 100%;
    }
    > .flex {
        flex: 1
    }
    > .flex-none {
        flex: none
    }
    > * {
        min-width: 0px;
        min-height: 0px;
    }
}

.mpk-flex{flex:1}
.mpk-flex-none{flex:none;}

.mpk-layout-fill {
    width: 100%;
    height: 100%;
}

.mpk-full {
    &.width {
        width: 100%;
    }
    &.height {
        height: 100%;
    }
    &.viewport-width{
        width: 100vw;
    }
    &.viewport-height{
        height: 100vh;
    }
}

.mpk-side-panel {
    width: 240px;
}

.mpk-align-center {
    text-align: center !important;
}

.mpk-align-right {
    text-align: right !important;
}

.mpk-align-left {
    text-align: left !important;
}

.mpk-content {
    overflow: auto;
    height: 100%;
}

/*COLOR*/
.mpk-bg-color-L1 {
    background-color: #fafafa
}

.mpk-bg-color-L2 {
    background-color: #f8f8f8
}

.mpk-bg-color-L3 {
    background-color: #f2f2f2
}

.mpk-bg-color-D1 {
    background-color: #263238
}

.mpk-bg-color-G1 {
    background-color: $mpk-grey-1 !important
}

.mpk-font-color-alert{ color: $mpk-color-alert }
.mpk-font-color-idle{ color: $mpk-color-idle }
.mpk-font-color-success{ color: $mpk-color-success }
.mpk-font-color-warn{ color: $mpk-color-warn }
.mpk-font-color-progress{ color: $mpk-color-progress }

.mpk-font-color-L1 {
    color: $mpk-font-color-L1 !important
}

.mpk-font-color-L2 {
    color: $mpk-font-color-L2 !important
}

.mpk-font-color-L3 {
    color: $mpk-font-color-L3 !important
}

.mpk-font-color-D1 {
    color: $mpk-font-color-D1 !important
}

.mpk-font-color-D2 {
    color: $mpk-font-color-D2 !important
}

.mpk-font-color-D3 {
    color: $mpk-font-color-D3 !important
}

.mpk-font-color-primary {
    color: $mpk-primary-color !important
}
.mpk-font-color-secondary {
    color: $mpk-secondary-color !important
}

.mpk-font-size-XS {
    font-size: $mpk-font-size-XS !important
}

.mpk-font-size-S {
    font-size: $mpk-font-size-S !important
}

.mpk-font-size-NS {
    font-size: $mpk-font-size-NS !important
}

.mpk-font-size-N {
    font-size: $mpk-font-size-N !important
}

.mpk-font-size-M {
    font-size: $mpk-font-size-M !important
}

.mpk-font-size-L {
    font-size: $mpk-font-size-L !important
}

.mpk-font-size-XL {
    font-size: $mpk-font-size-XL !important
}

.mpk-font-size-XXL {
    font-size: $mpk-font-size-XXL !important
}


/*FONT WEIGHT*/
.mpk-font-weight-T {
    font-weight: 200
}

.mpk-font-weight-L {
    font-weight: 300
}

.mpk-font-weight-N {
    font-weight: 400
}

.mpk-font-weight-M {
    font-weight: 500
}

.mpk-font-weight-B {
    font-weight: 600
}

.mpk-font-weight-EB {
    font-weight: 800
}

/*FONT FAMILY*/
.mpk-font-family-Main {
    font-family: 'Roboto', sans-serif
}

.mpk-font-family-Body {
    font-family: 'Open Sans', sans-serif
}

/*BORDER*/
.mpk-border.solid {
    border-style: solid !important;
    border-width: 0;
}

.mpk-border.dashed {
    border-style: dashed !important;
    border-width: 0
}

.mpk-border.dotted {
    border-style: dotted !important;
    border-width: 0
}

.mpk-border.light {
    border-color: $mpk-border-color-white !important;
    border-width: 0
}

.mpk-border.dark {
    border-color: $mpk-border-color-dark !important;
    border-width: 0
}

.mpk-border{
    &.all, &.border-all {
        border-width: thin;
    }
    &.top, &.border-top{
        border-top-width: thin;
    }
    &.bottom, &.border-bottom{
        border-bottom-width: thin;
    }
    &.left, &.border-left{
        border-left-width: thin;
    }
    &.right, &.border-right{
        border-right-width: thin;
    }
}

/*PADDING*/
.mpk-padding-S.all, .mpk-padding-S.padding-all {
    padding: $mpk-padding-S
}

.mpk-padding-NS.all, .mpk-padding-NS.padding-all {
    padding: $mpk-padding-NS
}

.mpk-padding-N.all, .mpk-padding-N.padding-all {
    padding: $mpk-padding-N
}

.mpk-padding-M.all, .mpk-padding-M.padding-all {
    padding: $mpk-padding-M
}

.mpk-padding-L.all, .mpk-padding-L.padding-all {
    padding: $mpk-padding-L
}

.mpk-padding-XL.all, .mpk-padding-XL.padding-all {
    padding: $mpk-padding-XL
}

.mpk-padding-S.left, .mpk-padding-S.padding-left {
    padding-left: $mpk-padding-S
}
.mpk-padding-NS.left, .mpk-padding-NS.padding-left {
    padding-left: $mpk-padding-NS
}
.mpk-padding-N.left, .mpk-padding-N.padding-left {
    padding-left: $mpk-padding-N
}

.mpk-padding-M.left, .mpk-padding-M.padding-left {
    padding-left: $mpk-padding-M
}

.mpk-padding-L.left, .mpk-padding-L.padding-left {
    padding-left: $mpk-padding-L
}

.mpk-padding-XL.left, .mpk-padding-XL.padding-left {
    padding-left: $mpk-padding-XL
}

.mpk-padding-S.right, .mpk-padding-S.padding-right {
    padding-right: $mpk-padding-S
}

.mpk-padding-NS.right, .mpk-padding-NS.padding-right {
    padding-right: $mpk-padding-NS
}

.mpk-padding-N.right, .mpk-padding-N.padding-right {
    padding-right: $mpk-padding-N
}

.mpk-padding-M.right, .mpk-padding-M.padding-right {
    padding-right: $mpk-padding-M
}

.mpk-padding-L.right, .mpk-padding-L.padding-right {
    padding-right: $mpk-padding-L
}

.mpk-padding-XL.right, .mpk-padding-XL.padding-right {
    padding-right: $mpk-padding-XL
}

.mpk-padding-S.top, .mpk-padding-S.padding-top {
    padding-top: $mpk-padding-S
}

.mpk-padding-NS.top, .mpk-padding-NS.padding-top {
    padding-top: $mpk-padding-NS
}

.mpk-padding-N.top, .mpk-padding-N.padding-top {
    padding-top: $mpk-padding-N
}

.mpk-padding-M.top, .mpk-padding-M.padding-top {
    padding-top: $mpk-padding-M
}

.mpk-padding-L.top, .mpk-padding-L.padding-top {
    padding-top: $mpk-padding-L
}

.mpk-padding-XL.top, .mpk-padding-XL.padding-top {
    padding-top: $mpk-padding-XL
}

.mpk-padding-S.bottom, .mpk-padding-S.padding-bottom {
    padding-bottom: $mpk-padding-S
}

.mpk-padding-NS.bottom, .mpk-padding-NS.padding-bottom {
    padding-bottom: $mpk-padding-NS
}

.mpk-padding-N.bottom, .mpk-padding-N.padding-bottom {
    padding-bottom: $mpk-padding-N
}

.mpk-padding-M.bottom, .mpk-padding-M.padding-bottom {
    padding-bottom: $mpk-padding-M
}

.mpk-padding-L.bottom, .mpk-padding-L.padding-bottom {
    padding-bottom: $mpk-padding-L
}

.mpk-padding-XL.bottom, .mpk-padding-XL.padding-bottom {
    padding-bottom: $mpk-padding-XL
}

/*MARGIN*/
.mpk-margin-S.all, .mpk-margin-S.margin-all {
    margin: $mpk-margin-S
}

.mpk-margin-NS.all, .mpk-margin-NS.margin-all {
    margin: $mpk-margin-NS
}

.mpk-margin-N.all, .mpk-margin-N.margin-all {
    margin: $mpk-margin-N
}

.mpk-margin-M.all, .mpk-margin-M.margin-all {
    margin: $mpk-margin-M
}

.mpk-margin-L.all, .mpk-margin-L.margin-all {
    margin: $mpk-margin-L
}

.mpk-margin-XL.all, .mpk-margin-XL.margin-all {
    margin: $mpk-margin-XL
}

.mpk-margin-S.left, .mpk-margin-S.margin-left {
    margin-left: $mpk-margin-S
}

.mpk-margin-NS.left, .mpk-margin-NS.margin-left {
    margin-left: $mpk-margin-NS
}

.mpk-margin-N.left, .mpk-margin-N.margin-left {
    margin-left: $mpk-margin-N
}

.mpk-margin-M.left, .mpk-margin-M.margin-left {
    margin-left: $mpk-margin-M
}

.mpk-margin-L.left, .mpk-margin-L.margin-left {
    margin-left: $mpk-margin-L
}

.mpk-margin-XL.left, .mpk-margin-XL.margin-left {
    margin-left: $mpk-margin-XL
}

.mpk-margin-S.right, .mpk-margin-S.margin-right {
    margin-right: $mpk-margin-S
}

.mpk-margin-NS.right, .mpk-margin-NS.margin-right {
    margin-right: $mpk-margin-NS
}

.mpk-margin-N.right, .mpk-margin-N.margin-right {
    margin-right: $mpk-margin-N
}

.mpk-margin-M.right, .mpk-margin-M.margin-right {
    margin-right: $mpk-margin-M
}

.mpk-margin-L.right, .mpk-margin-L.margin-right {
    margin-right: $mpk-margin-L
}

.mpk-margin-XL.right, .mpk-margin-XL.margin-right {
    margin-right: $mpk-margin-XL
}

.mpk-margin-S.top, .mpk-margin-S.margin-top {
    margin-top: $mpk-margin-S
}

.mpk-margin-NS.top, .mpk-margin-NS.margin-top {
    margin-top: $mpk-margin-NS
}

.mpk-margin-N.top, .mpk-margin-N.margin-top {
    margin-top: $mpk-margin-N
}

.mpk-margin-M.top, .mpk-margin-M.margin-top {
    margin-top: $mpk-margin-M
}

.mpk-margin-L.top, .mpk-margin-L.margin-top {
    margin-top: $mpk-margin-L
}

.mpk-margin-XL.top, .mpk-margin-XL.margin-top {
    margin-top: $mpk-margin-XL
}

.mpk-margin-S.bottom, .mpk-margin-S.margin-bottom {
    margin-bottom: $mpk-margin-S
}

.mpk-margin-NS.bottom, .mpk-margin-NS.margin-bottom {
    margin-bottom: $mpk-margin-NS
}

.mpk-margin-N.bottom, .mpk-margin-N.margin-bottom {
    margin-bottom: $mpk-margin-N
}

.mpk-margin-M.bottom, .mpk-margin-M.margin-bottom {
    margin-bottom: $mpk-margin-M
}

.mpk-margin-L.bottom, .mpk-margin-L.margin-bottom {
    margin-bottom: $mpk-margin-L
}

.mpk-margin-XL.bottom, .mpk-margin-XL.margin-bottom {
    margin-bottom: $mpk-margin-XL
}

/*ALLIGN*/
.mpk-align-center {
    text-align: center
}

.mpk-align-right {
    text-align: right
}

/*POINTER*/
.mpk-cursor-pointer {
    cursor: pointer
}

/*STATUS*/
.mpk-status {
    color: white;
    font-size: 10px;
    padding: 4px;
    border-radius: 2px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    &.clickable {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, .25);
        cursor: pointer;
        &:active {
            box-shadow: none;
        }
    }
    &.success {
        background-color: $mpk-color-success;
        &.outline {
            border: 2px solid $mpk-color-success;
            color: $mpk-color-success;
            background-color: transparent;
        }
    }
    &.warn {
        background-color: $mpk-color-warn;
        &.outline {
            border: 2px solid $mpk-color-warn;
            color: $mpk-color-warn;
            background-color: transparent;
        }
    }
    &.progress {
        background-color: $mpk-color-progress;
        &.outline {
            border: 2px solid $mpk-color-progress;
            color: $mpk-color-progress;
            background-color: transparent;
        }
    }
    &.alert {
        background-color: $mpk-color-alert;
        &.outline {
            border: 2px solid $mpk-color-alert;
            color: $mpk-color-alert;
            background-color: transparent;
        }
    }
    &.idle {
        background-color: $mpk-color-idle;
        &.outline {
            border: 2px solid $mpk-color-idle;
            color: $mpk-color-idle;
            background-color: transparent;
        }
    }
}

.mpk-relative-position {
    position: relative;
}

.mpk-position {
    &.sticky {
        position: sticky;
        top: 0;
        z-index: 5;
    }
    &.relative {
        position: relative;
    }
    &.fixed{
        position:fixed;
    }
    &.absolute{
        position: absolute;
    }
}

/*ANIMATION*/
.mpk-animation{
    &.slide-in{
        animation: slide-in .5s ;
        animation-direction: alternate;
    }
    &.slide-in-down{
        animation: slide-in-down .2s ease-out;
        animation-direction: alternate;
    }
    &.slide-left{
        animation: slide-left .4s ease-out;
        animation-direction: alternate;
    }
    &.slide-right{
        animation: slide-right .4s ease-out;
        animation-direction: alternate;
    }
}

@keyframes slide-in{
    0%{
        transform: translateY(48px); 
        opacity: 0;
    };
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-down{
    0%{
        transform: translateY(-48px); 
        opacity: 0;
    };
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-left{
    0%{
        transform: translateX(-100%); 
        opacity: 0;
    };
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-right{
    0%{
        transform: translateX(100%); 
        opacity: 0;
    };
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

/*OVERIDE*/
button span {
    font-weight: 400 !important;
}

button {
    &.dark{
        background:$mpk-dark-grey-1 !important;
    }
    &.md-btn--icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        .mdi {
            font-size: 20px;
        }
    }
}
.md-btn--text{
    min-width: 1px !important;
}

thead .md-table-row, tbody .md-table-row:not(:last-child){
    border-bottom: thin solid rgba(0,0,0,.052) !important;
}

.md-progress--linear{
    background: rgba(0,0,0,.25) !important;
}

.md-divider--text-field{
    &:after {
        height: 1px !important;
    }
}

.md-paper {
    background: white;
}

.md-table-column__fixed .md-table-checkbox--header {
    align-items: center;
}

.md-list-item {
    .md-icon {
        font-size: 18px;
    }
}

.md-text-field-container--input-block{
    input{
        margin-top: unset;
    }
}

/*LINK*/
.mpk-link {
    color: $mpk-color-link;
    cursor: pointer !important;
    &:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

.mpk-link-on-hover {
    cursor: pointer;
    &:hover {
        color: $mpk-color-link !important;
    }
}

/*BODY*/
.mpk-body{
    font-size: $mpk-font-size-M;
    line-height: $mpk-font-size-XXL;
}


/*AUTO SIZE*/
.mpk-auto-width{width: auto !important;}
.mpk-auto-height{height: auto !important;}

.iziToast-wrapper-bottomCenter{
    left: unset !important;
    text-align: right;
}
.md-dialog-footer{
    border-top: thin solid $mpk-border-color-dark;
    background: $mpk-action-footer-color;
}

.md-panel-column{
    color: $mpk-primary-color !important;
    font-weight: $mpk-font-weight-B;
}

.md-expansion-panel--expanded:not(:first-child){
    margin-top:0 !important;
}

.mpk-dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
  
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

.mpk-container{
    width: 1024px;
    margin: 0 auto;
}

.mpk-hide{display: none !important}
.mpk-hide-sm{display: inherit !important}
.mpk-hide-md{display: inherit !important}
.mpk-hide-xs{display: inherit !important}
.mpk-show{display: inherit !important}
.mpk-show-sm{display: none !important}
.mpk-show-md{display: none !important}
.mpk-show-xs{display: none !important}

.mpk-no-copy{
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */     
}

.mpk-no-wrap{
    white-space: nowrap;
}


/*MEDIA*/
@media screen and (max-width: $mpk-media-sm) {
    .mpk-layout {
        &.column-sm {
            flex-direction: column;
            &.child-fill > * {
                width: 100%;
            }
            &.reverse {
                flex-direction: column-reverse
            }
        }
        &.row-sm {
            flex-direction: row;
            &.reverse {
                flex-direction: row-reverse
            }
        }
        &.justify-center-sm {
            justify-content: center;
        }
        &.justify-arround-sm {
            justify-content: space-around;
        }
        &.justify-right-sm {
            justify-content: flex-end;
        }
        &.justify-between-sm {
            justify-content: space-between;
        }
    }
    .mpk-hide-sm {
        display: none !important;
    }
    .mpk-show-sm {
        display: unset !important;
    }
    .mpk-position {
        &.relative-sm {
            position: relative
        }
        &.sticky-sm {
            position: sticky;
            top: 0;
        }
        &.fixed-sm {
            position: fixed;
        }
    }
}

@media screen and (max-width: $mpk-media-xs) {
    .mpk-layout {
        &.column-xs {
            flex-direction: column;
            &.child-fill > * {
                width: 100%;
            }
            &.reverse {
                flex-direction: column-reverse
            }
        }
        &.row-xs {
            flex-direction: row;
            &.reverse {
                flex-direction: row-reverse
            }
        }
        &.justify-center-xs {
            justify-content: center;
        }
        &.justify-arround-xs {
            justify-content: space-around;
        }
        &.justify-right-xs {
            justify-content: flex-end;
        }
        &.justify-between-xs {
            justify-content: space-between;
        }
    }
    .mpk-hide-xs {
        display: none !important;
    }
    .mpk-show-xs {
        display: unset !important;
    }
    .mpk-position {
        &.relative-xs {
            position: relative
        }
        &.sticky-xs {
            position: sticky;
            top: 0;
        }
        &.fixed-xs {
            position: fixed;
        }
    }
}