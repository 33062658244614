@import '~react-mpk/styles/var';

$normal-width:400px;
$popup-width: 540px;
$popup-height: 640px;

$medium-width:640px;
$large-width:920px;

.container-paper {
  .container {
    width: auto;
    margin: 0 auto;
    margin-top: 48px;
    .brand {
      text-align: center;
      margin-bottom: 32px;
      .mpk-app-logo {
        height: 64px;
        justify-content: center !important;
      }
    }
    .md-paper {
      padding: $mpk-padding-L;
      margin: 0 auto;
      width: $normal-width;
      // position:relative;
      .md-progress--linear{
        position: absolute;
        top:0;
        width:100%;
        left:0;
        margin:0 !important;
      }
    }
    .header {
      font-size: $mpk-font-size-L;
      margin-bottom: $mpk-margin-N;
      h2, p{
        text-align: center;
      }
      p {
        font-size: $mpk-font-size-N;
        margin: 0;
        color: $mpk-font-color-D2;
      }
    }
    .footer {
      margin-top: 24px;
    }
    .action {
      > button.md-btn {
        width: 100%;
      }
      > div:first-child {
        text-align: center;
      }
      margin-top: $mpk-margin-M;
    }
    .info {
      width: $normal-width;
      margin: 24px auto;
      z-index: 10;
      line-height: 12px;
      .pajakku{
        b { color: $mpk-primary-color;}
        .link {
          > a {
            display: inline-block;
            padding: 0 4px;
            font-size:$mpk-font-size-S;
            position: relative;
            outline:none;
            text-decoration: dotted;
            color:$mpk-font-color-D2;
            &:after{
              content:".";
              position: absolute;
              right:0;
            }
            &:first-child{
              padding-left:0;
            }
            &:last-child{
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
    .completed{
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width:80px;
      height:80px;
      border:thin solid $mpk-color-progress;
      border-radius: 48px;
      .md-icon{
        font-size: 48px;
        color:$mpk-color-progress;
      }
    }
  }
  &.popup {
    .md-paper {
      width: $popup-width;
    }
    .info{
      width: $popup-width;
    }
    .action {
      button.md-btn {
        width: auto;
      }
    }
  }
  &.medium {
    .md-paper {
      width: $medium-width;
    }
    .info{
      width: $medium-width;
    }
    .action {
      button.md-btn {
        width: auto;
      }
    }
  }
  &.large {
    .md-paper {
      width: $large-width;
    }
    .info{
      width: $large-width;
    }
    .action {
      button.md-btn {
        width: auto;
      }
    }
  }
  .validation{
    color: $mpk-color-warn;
    font-weight: 500;
    font-size: $mpk-font-size-NS;
    padding:$mpk-padding-S $mpk-padding-N;
    border:2px solid $mpk-color-warn;
    margin-bottom:8px;
    .md-icon{
      margin-right:$mpk-margin-N;
      color:$mpk-color-warn;
    }
  }
}

@media screen and (max-width: $mpk-media-xs) {
  .container-paper {
    .container {
      padding: 0 $mpk-padding-N;
      .md-paper {
        width: 100%;
        padding: $mpk-padding-M;
        form{
          * {
            margin-right:0;
          }
        }
      }
      .info{
        width:100%;
      }
    }
    &.flex-height {
      height: 100%;
      top: 0;
      .container {
        height: 100%;
        .brand {
          text-align: center;
          padding-top: 24px;
          margin-bottom: 24px;
          
        }
        margin-top: 0;
        display: flex;
        flex-direction: column;
        .cb {
          flex: 1;
          display: flex;
          flex-direction: column;
          .header {
            flex: none;
            margin: 0;
          }
          .children {
            flex: 1;
            form {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
            }
            .action {
              margin: 0;
            }
          }
          .md-paper{
            display: flex;
            flex-direction: column;
            flex: 1;
          }
          .footer {
            flex: none;
            margin: 0;
          }
          .info {
            flex: 0;
          }
        }
      }
    }
  }
}