@import '../../styles/var';

.mpk-app-logo-static{
  display: flex;
  align-items: center;
  justify-content: center;
  .picture-mark{
    height:100%;
  }
  .logo-type-container{
    height:64%;
    margin-left:8px;
    img{
      height: 100%;
    }
  }
  
}