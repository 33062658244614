@import '~react-mpk/styles/var';

$panel-width:800px;

.account-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .panel{
    padding:48px 0;
    width: $panel-width;
    position: relative;
    display:flex;
    background-color: white;
    * {
      flex:1;
    }
    .identity{
      padding:48px;
      text-align: center;
      img.mpk-logo{
        height:80px;
      }
      img.ills{
        width:180px;
        margin-top:$mpk-padding-M;
      }
    }

    .content{
      padding:48px;
      border-left:thin solid #f2f2f2;
      .title{
        font-weight: 600;
        font-size: 18px;
        margin-bottom:16px;
      }
      .opt{
        margin-top:16px;
        text-align: center;
        .mpk-link{
          font-size: $mpk-font-size-NS;
        }
      }
    }

    .action{
      right:24px;
      top:24px;
      position: absolute;
    }
  }

  .footer{
    padding:$mpk-padding-S 0;
    width: $panel-width;
    font-size:$mpk-font-size-NS;
    color:$mpk-font-color-D3;
    .link{
      &:after{
        content:'|';
        margin:0 8px;
      }
      &:last-child:after{
        display: none;
      }
    }
  }
}

$gradient-primary-2:#eee;
$gradient-primary-1:#e2e2e2;

.account{
  background: #e2e2e2;
  overflow-x:hidden;
  position:relative;
  .bg{
    positon:fixed;
    overflow:hidden;
    width:100%;
    height:100%;
    z-index: 0;
    .parallelogram-1 {
      position:absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity:.54;
      -webkit-transform: skew(56deg);
      -moz-transform: skew(56deg);
      -o-transform: skew(56deg);
      background: $gradient-primary-1; /* Old browsers */
      background: -moz-linear-gradient(top, $gradient-primary-1 56%, $gradient-primary-2 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, $gradient-primary-1 56%,$gradient-primary-2 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, $gradient-primary-1 56%,$gradient-primary-2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-2 {
      position:absolute;
      top:0;
      right:0;
      width: 50%;
      height: 100%;
      opacity:1;
      -webkit-transform: skew(56deg);
      -moz-transform: skew(56deg);
      -o-transform: skew(56deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(top, $gradient-primary-1 0%, $gradient-primary-2 24%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, $gradient-primary-1 0%,$gradient-primary-2 24%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, $gradient-primary-1 0%,$gradient-primary-2 24%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-3 {
      position:absolute;
      top:0;
      left:0%;
      opacity:.24;
      width: 18%;
      height: 100%;
      -webkit-transform: skew(-12deg);
      -moz-transform: skew(-12deg);
      -o-transform: skew(-12deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(top, $gradient-primary-1 0%, $gradient-primary-2 52%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, $gradient-primary-1 0%,$gradient-primary-2 52%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, $gradient-primary-1 0%,$gradient-primary-2 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-4 {
      position:absolute;
      top:0;
      right:30%;
      opacity:.32;
      width: 40%;
      height: 100%;
      -webkit-transform: skew(-12deg);
      -moz-transform: skew(-12deg);
      -o-transform: skew(-12deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(top, $gradient-primary-2 0%, $gradient-primary-1 52%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, $gradient-primary-2 0%,$gradient-primary-1 52%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, $gradient-primary-2 0%,$gradient-primary-1 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-5 {
      position:absolute;
      top:0;
      right:5%;
      opacity:.4;
      width: 40%;
      height: 100%;
      -webkit-transform: skew(-12deg);
      -moz-transform: skew(-12deg);
      -o-transform: skew(-12deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(top, $gradient-primary-2 0%, $gradient-primary-1 52%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, $gradient-primary-2 0%,$gradient-primary-1 52%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, $gradient-primary-2 0%,$gradient-primary-1 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
    }
  }

  .account-main{
    position:absolute;
    width: 100%;
    height:100%;
  }

  .account-header{
    width: 100%;
    height: auto;
  }
}
